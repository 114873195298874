import { AccountInfo } from "./AccountInfo";
import { PopularCountries } from "../../components";
import { createAccount, findAccount, searchAccounts } from "../../api";
import {
  Input,
  Select,
  useErrors,
  ErrorMessage,
  PhoneNumber,
} from "react-components";
import {
  AccountDO,
  arrOfSize,
  countriesByCode,
  DannoSearchAccountReq,
  fullName,
  regionsByCountryCode,
  sortRegionsAlpha,
} from "data-model";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import clsx from "clsx";

const defaultLimit = 5;

const grid3frAuto2fr = { gridTemplateColumns: "3fr auto 2fr" };

interface Props {
  className?: string;
  onAccountApply: (account: AccountDO) => void;
}

const AccountFinder: FC<Props> = ({ className, onAccountApply }) => {
  const isMounted = useRef(false);

  const form = useRef<HTMLFormElement>(null);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [accounts, setAccounts] = useState<AccountDO[]>([]);
  const [accountIdx, setAccountIdx] = useState(-1);
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(0);
  const account: AccountDO | undefined = accounts[accountIdx];

  const [errors, catchErrors, setErrors] = useErrors();

  const isEmpty =
    !phoneNumber &&
    !email &&
    !firstName &&
    !lastName &&
    !line1 &&
    !line2 &&
    !country &&
    !state &&
    !city &&
    !zip;
  const isComplete =
    phoneNumber &&
    email &&
    firstName &&
    lastName &&
    line1 &&
    country &&
    state &&
    city &&
    zip;

  const search = async (args: DannoSearchAccountReq, signal?: AbortSignal) => {
    const accounts = await searchAccounts(args, { signal });
    setAccounts(accounts);
    setAccountIdx(-1);
    setLimit(args.limit);
    setOffset(args.offset);
  };

  // Why useCallback? https://stackoverflow.com/a/61786423
  const debounceSearch = useCallback(debounce(search, 500), []);

  const formValues = {
    phoneNumber,
    email,
    firstName,
    lastName,
    line1,
    line2,
    country,
    state,
    city,
    zip,
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const controller = new AbortController();

    if (!isEmpty) {
      debounceSearch({ ...formValues, limit, offset }, controller.signal);
    } else {
      setAccounts([]); // clean up previous results, if any
    }

    return () => controller.abort();
  }, [
    phoneNumber,
    email,
    firstName,
    lastName,
    line1,
    line2,
    country,
    state,
    city,
    zip,
  ]);

  return (
    <div className={className}>
      <form
        ref={form}
        className="is-grid is-align-items-center is-grid-template-columns-1fr-4fr is-column-gap-2"
        autoComplete="off"
      >
        <label htmlFor="phone-number">Mobile:</label>
        <PhoneNumber
          id="phone-number"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />

        <label htmlFor="email" className="margin-bottom-3">
          Email:
        </label>
        <Input
          id="email"
          type="email"
          className="margin-bottom-3"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />

        <label htmlFor="first-name">First Name:</label>
        <Input
          id="first-name"
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />

        <label htmlFor="last-name">Last Name:</label>
        <Input
          id="last-name"
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />

        <label htmlFor="line-1">Address:</label>
        <Input
          id="line-1"
          value={line1}
          onChange={(e) => setLine1(e.currentTarget.value)}
        />

        <Input
          className="is-grid-column-2-3"
          value={line2}
          onChange={(e) => setLine2(e.currentTarget.value)}
        />

        <label htmlFor="country">Country:</label>
        <div className="is-grid is-align-items-center" style={grid3frAuto2fr}>
          <Select
            id="country"
            value={country}
            onChange={(e) => {
              setCountry(e.currentTarget.value);
              setState("");
            }}
          >
            <option value="" />
            <PopularCountries />
            {Object.entries(countriesByCode).map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>

          <label htmlFor="city" className="margin-x-2">
            City:
          </label>
          <Input
            id="city"
            value={city}
            onChange={(e) => setCity(e.currentTarget.value)}
          />
        </div>

        <label htmlFor="state">State:</label>
        <div className="is-grid is-align-items-center" style={grid3frAuto2fr}>
          <Select
            id="state"
            value={state}
            onChange={(e) => setState(e.currentTarget.value)}
          >
            <option value="" />
            {country &&
              Object.entries(regionsByCountryCode[country])
                .sort(sortRegionsAlpha)
                .map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
          </Select>

          <label htmlFor="zip" className="margin-x-2">
            Zip:
          </label>
          <Input
            id="zip"
            value={zip}
            onChange={(e) => setZip(e.currentTarget.value)}
          />
        </div>

        <div className="is-grid-column-2-3 is-flex is-justify-content-space-between margin-top-3">
          <button
            type="button"
            className="button is-ghost is-link is-paddingless"
            onClick={() => {
              setPhoneNumber("");
              setEmail("");
              setFirstName("");
              setLastName("");
              setLine1("");
              setLine2("");
              setCountry("");
              setState("");
              setCity("");
              setZip("");
              setAccounts([]);
              setAccountIdx(-1);
              setLimit(defaultLimit);
              setOffset(0);
              setErrors([]);
            }}
            disabled={isEmpty}
          >
            Clear
          </button>
          <button
            type="button"
            className="button is-light-blue"
            disabled={!isComplete || !!accounts.length}
            onClick={() => {
              if (!form.current?.reportValidity()) return;
              catchErrors(async () => {
                const account = await createAccount(formValues);
                onAccountApply(account);
              });
            }}
          >
            Create New Account
          </button>
        </div>

        {!!errors.length && (
          <div className="is-grid-column-2-3 margin-top-3">
            <ErrorMessage errors={errors} className="is-marginless" />
          </div>
        )}
      </form>

      <div className="is-grid is-grid-template-columns-3fr-2fr-1fr margin-top-5">
        <strong className="padding-1">Name</strong>
        <strong className="padding-1">Mobile</strong>
        <strong className="padding-1">Zip</strong>
      </div>
      <div
        className="is-grid is-grid-template-columns-3fr-2fr-1fr is-gap-1px has-background-gray has-border-gray"
        style={{
          gridAutoRows: "calc(16px * 1.15 + 10px)",
          // maxHeight: `calc(${displayCount} * 28.4px + ${displayCount} * 1px + 1px)`,
        }}
      >
        {accounts.map((account, idx) => {
          const isSelected = accountIdx === idx;
          const className = clsx(
            isSelected
              ? "has-background-light-blue has-text-white"
              : "has-background-white",
            "padding-1 has-ellipsis"
          );
          return (
            <div
              key={account.id}
              className="is-display-contents is-clickable"
              onClick={() => setAccountIdx(isSelected ? -1 : idx)}
            >
              <span className={className} title={fullName(account)}>
                {fullName(account)}
              </span>
              <span className={className} title={account.phoneNumber}>
                {account.phoneNumber}
              </span>
              <span className={className} title={account.address.zip}>
                {account.address.zip}
              </span>
            </div>
          );
        })}
        {accounts.length < limit &&
          arrOfSize(3 * (limit - accounts.length)).map((_, idx) => (
            <span key={idx} className="has-background-white padding-1" />
          ))}
      </div>
      <div className="is-grid is-grid-template-columns-2fr-1fr-auto is-align-items-center margin-top-1">
        <div className="is-flex is-align-items-center">
          <label htmlFor="limit" className="margin-x-1">
            Display:
          </label>
          <Select
            id="limit"
            value={limit}
            onChange={(e) => {
              const limit = +e.currentTarget.value;
              if (isEmpty) {
                setLimit(limit);
              } else {
                search({ ...formValues, limit, offset: 0 });
              }
            }}
          >
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
          </Select>
        </div>
        <span>Page: {!!accounts.length && (offset + limit) / limit}</span>
        <div className="is-flex">
          <button
            className="button is-ghost is-link is-paddingless margin-right-4"
            disabled={offset === 0}
            onClick={() =>
              search({ ...formValues, limit, offset: offset - limit })
            }
          >
            Previous
          </button>
          <button
            className="button is-ghost is-link is-paddingless"
            disabled={!accounts.length || accounts.length < limit}
            onClick={() =>
              search({ ...formValues, limit, offset: offset + limit })
            }
          >
            Next
          </button>
        </div>
      </div>

      <div className="is-flex is-align-items-flex-start margin-top-5">
        <div className="is-flex-1">
          {account && <AccountInfo account={account} withName />}
        </div>
        <button
          className="button is-light-blue"
          disabled={!account}
          onClick={async () => {
            const accountWithBookings = await findAccount(account.id);
            onAccountApply(accountWithBookings);
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export { AccountFinder };
