import { LOGIN_KEY } from "./state";
import {
  apiHelpers,
  DannoAddBookingToPartyReq,
  DannoAddBookingToPartyRes,
  DannoApplyDiscountReq,
  DannoApplyDiscountRes,
  DannoAvailabilityReq,
  DannoAvailabilityRes,
  DannoBookingOwnerReq,
  DannoBookingOwnerRes,
  DannoCancelBookingReq,
  DannoCancelBookingRes,
  DannoCreateAccountReq,
  DannoCreateAccountRes,
  DannoCreatePartyReq,
  DannoCreatePartyRes,
  DannoDeleteHoldRes,
  DannoEditAccountReq,
  DannoEditAccountRes,
  DannoEditDetailsReq,
  DannoEditDetailsRes,
  DannoEditTransportReq,
  DannoEditTransportRes,
  DannoExtendDeadlineReq,
  DannoExtendDeadlineRes,
  DannoFindAccountRes,
  DannoFindBookingRes,
  DannoGetFormTokenReq,
  DannoGetFormTokenRes,
  DannoHoldSpaceReq,
  DannoHoldSpaceRes,
  DannoMakeNoteReq,
  DannoMakeNoteRes,
  DannoMakeRequestReq,
  DannoMakeRequestRes,
  DannoOptForTourNoticeReq,
  DannoOptForTourNoticeRes,
  DannoOptOutOfTourNoticeReq,
  DannoOptOutOfTourNoticeRes,
  DannoReconfirmBookingRes,
  DannoRecoverPasswordRes,
  DannoRemoveBookingFromPartyRes,
  DannoReplaceAccountReq,
  DannoReplaceAccountRes,
  DannoRequestTppRefundReq,
  DannoRequestTppRefundRes,
  DannoRevokeRequestRes,
  DannoSavePaymentReq,
  DannoSavePaymentRes,
  DannoSearchAccountReq,
  DannoSearchAccountRes,
  DannoSearchBookingReqSerialized,
  DannoSearchBookingRes,
  DannoSearchFlightsReq,
  DannoSearchFlightsRes,
  DannoSearchPartiesReq,
  DannoSearchPartiesRes,
  DannoSendEmailReq,
  DannoSendEmailRes,
  JsongoLoginReq,
  JsongoLoginRes,
  JsongoLogoutRes,
} from "data-model";

export const API_URL = `https://${process.env.API_HOSTNAME}/v1/danno`;

const { json, jsonPost, jsonQuery, jsonDelete, jsonPatch, jsonPut } =
  apiHelpers(API_URL, (err) => {
    if (err.status === 401 && localStorage.getItem(LOGIN_KEY)) {
      localStorage.removeItem(LOGIN_KEY);
      location.reload();
    }
  });

export const logIn = (body: JsongoLoginReq): Promise<JsongoLoginRes> =>
  jsonPost("/login", body);

export const logOut = (): Promise<JsongoLogoutRes> => jsonPost("/logout", {});

export const sendEmail = (
  body: DannoSendEmailReq
): Promise<DannoSendEmailRes> => jsonPost("/email", body);

// Check Availability

export const checkAvailability = (
  params: DannoAvailabilityReq
): Promise<DannoAvailabilityRes> => jsonQuery("/bookings/availability", params);

export const lookUpBookingOwner = (
  params: DannoBookingOwnerReq
): Promise<DannoBookingOwnerRes> => jsonQuery("/bookings/owner", params);

export const searchAccounts = (
  params: DannoSearchAccountReq,
  init?: RequestInit
): Promise<DannoSearchAccountRes> => jsonQuery("/accounts", params, init);

export const createAccount = (
  payload: DannoCreateAccountReq
): Promise<DannoCreateAccountRes> => jsonPost("/accounts", payload);

export const deleteHold = (holdId: number): Promise<DannoDeleteHoldRes> =>
  jsonDelete(`/holds/${holdId}`);

export const holdSpace = (
  payload: DannoHoldSpaceReq
): Promise<DannoHoldSpaceRes> => jsonPost("/bookings", payload);

// Manage Booking

export const searchBookings = (
  params: DannoSearchBookingReqSerialized,
  init?: RequestInit
): Promise<DannoSearchBookingRes> => jsonQuery("/bookings", params, init);

export const findBooking = (bookingId: number): Promise<DannoFindBookingRes> =>
  json(`/bookings/${bookingId}`);

export const reconfirmBooking = (
  bookingId: number
): Promise<DannoReconfirmBookingRes> =>
  jsonPost(`/bookings/${bookingId}/reconfirm`, {});

export const replaceAccount = (
  bookingId: number,
  payload: DannoReplaceAccountReq
): Promise<DannoReplaceAccountRes> =>
  jsonPut(`/bookings/${bookingId}/owner`, payload);

export const extendDeadline = (
  bookingId: number,
  payload: DannoExtendDeadlineReq
): Promise<DannoExtendDeadlineRes> =>
  jsonPatch(`/bookings/${bookingId}/deadline`, payload);

export const cancelBooking = (
  bookingId: number,
  payload: DannoCancelBookingReq
): Promise<DannoCancelBookingRes> =>
  jsonPost(`/bookings/${bookingId}/cancel`, payload);

export const makeRequest = (
  bookingId: number,
  payload: DannoMakeRequestReq
): Promise<DannoMakeRequestRes> =>
  jsonPost(`/bookings/${bookingId}/requests`, payload);

export const revokeRequest = (
  requestId: number
): Promise<DannoRevokeRequestRes> => jsonDelete(`/requests/${requestId}`);

export const editDetails = (
  guestId: number,
  payload: DannoEditDetailsReq,
  init?: RequestInit
): Promise<DannoEditDetailsRes> =>
  jsonPatch(`/guests/${guestId}/details`, payload, init);

export const searchFlights = (
  params: DannoSearchFlightsReq,
  init?: RequestInit
): Promise<DannoSearchFlightsRes> => jsonQuery("/flights", params, init);

export const editTransport = (
  guestId: number,
  payload: DannoEditTransportReq,
  init?: RequestInit
): Promise<DannoEditTransportRes> =>
  jsonPatch(`/guests/${guestId}/transport`, payload, init);

export const applyDiscount = (
  feeId: number,
  payload: DannoApplyDiscountReq
): Promise<DannoApplyDiscountRes> =>
  jsonPost(`/fees/${feeId}/discounts`, payload);

export const getFormToken = (
  bookingId: number,
  payload: DannoGetFormTokenReq
): Promise<DannoGetFormTokenRes> =>
  jsonPost(`/bookings/${bookingId}/payments/token`, payload);

export const savePayment = (
  bookingId: number,
  payload: DannoSavePaymentReq
): Promise<DannoSavePaymentRes> =>
  jsonPost(`/bookings/${bookingId}/payments`, payload);

export const makeNote = (
  payload: DannoMakeNoteReq
): Promise<DannoMakeNoteRes> => jsonPut(`/notes`, payload);

export const searchParties = (
  params: DannoSearchPartiesReq
): Promise<DannoSearchPartiesRes> => jsonQuery("/parties", params);

export const createParty = (
  payload: DannoCreatePartyReq
): Promise<DannoCreatePartyRes> => jsonPost("/parties", payload);

export const addBookingToParty = (
  partyId: number,
  payload: DannoAddBookingToPartyReq
): Promise<DannoAddBookingToPartyRes> =>
  jsonPost(`/parties/${partyId}/bookings`, payload);

export const removeBookingFromParty = (
  partyId: number,
  bookingId: number
): Promise<DannoRemoveBookingFromPartyRes> =>
  jsonDelete(`/parties/${partyId}/bookings/${bookingId}`);

export const requestTppRefund = (
  bookingId: number,
  payload: DannoRequestTppRefundReq
): Promise<DannoRequestTppRefundRes> =>
  jsonPost(`/bookings/${bookingId}/refunds`, payload);

// Manage Account

export const findAccount = (accountId: number): Promise<DannoFindAccountRes> =>
  json(`/accounts/${accountId}`);

export const editAccount = (
  accountId: number,
  payload: DannoEditAccountReq
): Promise<DannoEditAccountRes> => jsonPatch(`/accounts/${accountId}`, payload);

export const recoverPassword = (
  accountId: number
): Promise<DannoRecoverPasswordRes> =>
  jsonPost("/password/recover", { accountId });

export const optForTourNotice = (
  accountId: number,
  payload: DannoOptForTourNoticeReq
): Promise<DannoOptForTourNoticeRes> =>
  jsonPost(`/accounts/${accountId}/notices`, payload);

export const optOutOfTourNotice = (
  accountId: number,
  payload: DannoOptOutOfTourNoticeReq
): Promise<DannoOptOutOfTourNoticeRes> =>
  jsonDelete(`/accounts/${accountId}/notices`, payload);
