import { Props } from "./RefundTpp";
import { db } from "../../../jsongo";
import { cancelBooking } from "../../../api";
import { FC, useState } from "react";
import {
  CancelCosts,
  cancellationCosts,
  daysUntilDepartureEOD,
  forcedCancellationCosts,
  FORCED_CANCELLATION,
  formatCents,
  fullName,
  isDepartureCancelled,
  isFeePaid,
  SeriesDO,
  seriesIdFrom,
  TourYearDO,
  yearFromDate,
  MMM_d_yyyy,
  nowInCT,
} from "data-model";
import {
  CancelEditor,
  CancelSelector,
  Checkbox,
  DaysPriorToTour,
  DottedHeading,
  ErrorMessage,
  Input,
  tableOfCancellationFees,
  Textarea,
  useErrors,
} from "react-components";
import { klona } from "klona/json";
import clsx from "clsx";

const CancelOptions: FC<Props> = ({ booking, onClose, setBooking }) => {
  const year = yearFromDate(booking.departedAt);
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: booking.tour,
    year,
  }) as TourYearDO;
  const series = db.series.findByIdOrFail(
    seriesIdFrom(booking.series, year)
  ) as SeriesDO;
  const ownerFullName = fullName(booking.owner);

  const [guestsToCancel, setGuestsToCancel] = useState(
    booking.guests.map(() => false)
  );
  const [cancelCosts, setCancelCosts] = useState(
    cancellationCosts(
      guestsToCancel,
      booking,
      tourYear,
      booking.departure.priceName
    )
  );
  const [isForced, setIsForced] = useState(false);
  const [reason, setReason] = useState("");
  const [requestedBy, setRequestedBy] = useState(ownerFullName);

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const totalPaid = guestsToCancel.reduce((acc, isCancelling, posIdx) => {
    if (isCancelling) {
      booking.guests[posIdx].fees.forEach((fee) => {
        if (isFeePaid(fee)) {
          acc += fee.total;
        }
      });
    }
    return acc;
  }, 0);
  const totalFees = cancelCosts.reduce((acc, room) => {
    for (const result of room) {
      if (result) {
        for (const cancelFee of result.cancellationFees) {
          acc += cancelFee.amount;
        }
      }
    }
    return acc;
  }, 0);
  const totalRefund = totalPaid - totalFees;

  const handleCancelFeeChange = (
    roomIdx: number,
    guestIdx: number,
    feeIdx: number,
    amount: number
  ) =>
    setCancelCosts((costs) => {
      const copy = klona(costs);
      copy[roomIdx][guestIdx]!.cancellationFees[feeIdx].amount = amount;
      return copy;
    });

  const handleCancel = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const [updatedBooking, note] = await cancelBooking(booking.id, {
          guestsToCancel,
          cancelCosts: filterZeroDollarFees(cancelCosts),
          reason,
          requestedBy,
        });
        setBooking((b) => ({
          ...b,
          notes: note
            ? [note, ...b.notes.filter((n) => n.type !== note.type)]
            : b.notes,
          roomGuests: updatedBooking.roomGuests,
          cancelledAt: updatedBooking.cancelledAt,
          updatedAt: updatedBooking.updatedAt,
          guests: b.guests
            .filter((_, posIdx) => !guestsToCancel[posIdx])
            .map((g, posIdx) => {
              const updatedGuest = updatedBooking.guests[posIdx];
              return {
                ...g,
                fees: [
                  ...g.fees,
                  ...updatedGuest.fees.filter(
                    ({ id }) => !g.fees.some((f) => f.id === id)
                  ),
                ],
                roomRequests: updatedBooking.roomGuests,
                party: updatedBooking.cancelledAt ? undefined : b.party,
                roomIndex: updatedGuest.roomIndex,
                guestIndex: updatedGuest.guestIndex,
                updatedAt: updatedGuest.updatedAt,
              };
            }),
        }));
        onClose();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div id="modal-description" className="is-flex">
      <aside className="margin-left-2 margin-right-4">
        <p className="margin-top-0">Select Cancellation:</p>

        <CancelSelector
          disabled={isForced || isLoading}
          guests={booking.guests}
          guestsToCancel={guestsToCancel}
          onChange={(guestsToCancel) => {
            setGuestsToCancel(guestsToCancel);
            setCancelCosts(
              cancellationCosts(
                guestsToCancel,
                booking,
                tourYear,
                booking.departure.priceName
              )
            );
          }}
          owner={booking.owner}
          roomGuests={booking.roomGuests}
        />

        <hr className="margin-y-3" />

        <Checkbox
          id="force-cancel"
          disabled={
            isLoading || !isDepartureCancelled(booking.departure, series)
          }
          checked={isForced}
          onChange={() => {
            const isForceOn = !isForced;
            const newGuestsToCancel = booking.guests.map(() => isForceOn);
            setIsForced(isForceOn);
            setGuestsToCancel(newGuestsToCancel);
            setCancelCosts(
              isForceOn
                ? forcedCancellationCosts(newGuestsToCancel, booking.roomGuests)
                : cancellationCosts(
                    newGuestsToCancel,
                    booking,
                    tourYear,
                    booking.departure.priceName
                  )
            );
            setReason(isForceOn ? FORCED_CANCELLATION.reason : "");
            setRequestedBy(
              isForceOn ? FORCED_CANCELLATION.requestedBy : ownerFullName
            );
          }}
        >
          Forced Cancellation
        </Checkbox>
      </aside>
      <div>
        <p className="margin-top-0">
          Preview Cancellation: {nowInCT().toFormat(MMM_d_yyyy)}
          <DaysPriorToTour
            daysLeft={daysUntilDepartureEOD(booking.departedAt)}
          />
        </p>
        <div className="margin-bottom-4">
          <div
            style={{ minHeight: 348 }}
            className="is-grid is-grid-template-columns-repeat-3-1fr is-column-gap-4 is-row-gap-4 is-align-items-flex-start"
          >
            <CancelEditor
              cancelCosts={cancelCosts}
              guests={booking.guests}
              guestsToCancel={guestsToCancel}
              onCancelFeeChange={handleCancelFeeChange}
              owner={booking.owner}
            />
          </div>
          <hr className="margin-top-4 margin-bottom-3" />
          <div className="is-flex is-justify-content-space-between">
            <div>
              <label htmlFor="reason-for-cancellation">
                Reason for cancellation
              </label>
              <Textarea
                id="reason-for-cancellation"
                value={reason}
                onChange={(e) => setReason(e.currentTarget.value)}
                disabled={isLoading || isForced}
              />
            </div>
            <div className="is-flex is-flex-direction-column">
              <label htmlFor="requested-by">Requested By (shows on PDF)</label>
              <Input
                id="requested-by"
                value={requestedBy}
                onChange={(e) => setRequestedBy(e.currentTarget.value)}
                disabled={isLoading || isForced}
              />
            </div>
            <div className="is-flex">
              <div style={{ minWidth: 300 }} className="margin-right-4">
                <h2 className="margin-y-0 is-flex">
                  <span className="is-flex-1">Total Amount Paid</span>
                  <span>{formatCents(totalPaid, false)}</span>
                </h2>
                <h2 className="margin-y-0 is-flex">
                  <span className="is-flex-1">Total Fees & Deductibles</span>
                  <span>{formatCents(totalFees, false)}</span>
                </h2>
                <DottedHeading
                  className={clsx(
                    "margin-y-0",
                    totalRefund > 0 && "has-text-green"
                  )}
                  heading="h2"
                  left="Total Refund"
                  right={formatCents(totalPaid - totalFees, false)}
                />
              </div>
              <div className="is-flex is-flex-direction-column">
                <button
                  className="button is-light-blue is-outlined is-size-7 padding-x-4 margin-bottom-1"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Close – Do Not Cancel
                </button>
                <button
                  className="button is-light-blue is-size-7"
                  disabled={
                    isLoading ||
                    guestsToCancel.every((c) => !c) ||
                    totalFees > totalPaid ||
                    !requestedBy
                  }
                  onClick={handleCancel}
                >
                  Proceed to Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="is-flex">
          {tableOfCancellationFees}

          <article style={{ maxWidth: 640 }} className="margin-left-4">
            <ErrorMessage
              errors={errors}
              className="margin-top-0 is-justify-content-flex-end"
            />

            <p className="margin-top-0">
              Time of cancellation will be when notice is received in Caravan’s
              office. Cancellation fees apply even if a person transfers to
              another tour or start date.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export { CancelOptions };

const filterZeroDollarFees = (cancelCosts: CancelCosts): CancelCosts =>
  cancelCosts.map((roomCosts) => {
    return roomCosts.map((guestCosts) => {
      if (guestCosts) {
        return {
          ...guestCosts,
          cancellationFees: guestCosts.cancellationFees.filter(
            (cancelFee) => cancelFee.amount > 0
          ),
        };
      }
      return guestCosts;
    });
  });
